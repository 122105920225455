
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";

import Header from "@/components/header.vue";
import { onMounted, ref, reactive } from "vue";
import { getRecruitDetail, submitRecruit } from "@/service/discover";
import router from "@/router";
import { openTips, openModal } from "@/utils/alert";
import { nameRegular, phoneRegular } from "@/utils/regular";
import { userinfo, checklogin } from "@/utils/usermodule";
import { getDate, getDateTime } from "@/utils/moment";

export default {
  name: "RecruitDetail",
  components: { Header, IonHeader, IonContent, IonPage },
  setup() {
    const {
      currentRoute: {
        value: {
          query: { id },
        },
      },
    } = router as any;
    const detail: any = ref([]);
    const form: any = reactive({
      name: "",
      phone: "",
      introduce: "",
    });

    const getDetail = async () => {
      const result = await getRecruitDetail({
        code: id,
      });
      detail.value = result;
    };

    const submit = async () => {
      //检测是否登录
      if (!checklogin()) {
        return;
      }

      if (nameRegular(form.name)) {
        openTips("姓名输入有误!");
        return;
      }
      if (phoneRegular(form.phone)) {
        openTips("手机号输入有误!");
        return;
      }
      if (form.introduce === "" || form.introduce.length > 100) {
        openTips("简介内容有误!");
        return;
      }
      const result = await submitRecruit({
        JobId: id,
        Mobile: form.phone,
        UserDes: form.introduce,
        UserName: form.name,
        UserId: "system",
      });
      if (result) {
        openModal(`您已成功投递「${detail.value.jobTitle}」`);
        form.name = "";
        form.phone = "";
        form.introduce = "";
      }
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      getDetail();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return { detail, form, submit, getDate };
  },
};
