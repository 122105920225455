import { local } from "@/utils/storage.service";
import { skipRouter } from "@/router";

export const userinfo = {
  userid: "",
  username: "未登录",
  usertype: "",
  mobile: "",
  workerid: "",
  supplierid: "",
  islogin: "0"
}
//检查是否登录或者是否完善信息
export function checklogin() {
  const loginuser = local.get("loginuser");
  console.log(loginuser);
  if (loginuser == null) {
    local.set("loginuser", userinfo);
    skipRouter("/login");
    return false;
  } else {
    if (loginuser.userid == null || loginuser.userid == "") {
      skipRouter("/login");
      return false;
    } else {
      return true;
    }
  }
}

//检查是否登录或者是否完善信息
export function checkisallinfo(url: any) {
  const loginuser = local.get("loginuser");
  if (loginuser == null) {
    skipRouter("/login/index");
  } else {
    if (userinfo.usertype === "3") {
      skipRouter("/login/selected-identity");
    }
  }
}
