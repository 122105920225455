import request from '@/utils/http.service';
import { discoverApi } from '@/utils/api.config';

export function getRecruitList(data: any) {
  return request({
    url: discoverApi.recruitList,
    method: 'POST',
    data
  }).then((results: any) => ({
    ...results,
    rows: results.rows.map((row: any) => {
      const { jobId, createTime, jobTitle, projectId } = row;
      return {
        ...row,
        id: jobId,
        date: createTime,
        title: jobTitle,
        address: projectId
      }
    })
  }))
}

export function getRecruitDetail(data: any) {
  return request({
    url: discoverApi.recruitDetail,
    method: 'POST',
    data
  }).then((results) => results)
}

export function submitRecruit(data: any) {
  return request({
    url: discoverApi.submitRecruit,
    method: 'POST',
    data
  }).then(() => true)
}

export function getTenderList(data: any) {
  return request({
    url: discoverApi.tenderList,
    method: 'POST',
    data
  }).then((results: any) => ({
    ...results,
    rows: results.rows.map((row: any) => {
      const { tenderId, publishTime, tenderName, tenderAddress } = row;
      return {
        ...row,
        id: tenderId,
        date: publishTime,
        title: tenderName,
        address: tenderAddress
      }
    })
  }))
}

export function getTenderDetail(data: any) {
  return request({
    url: discoverApi.tenderDetail,
    method: 'POST',
    data
  }).then((results) => results)
}

export function submitTender(data: any) {
  return request({
    url: discoverApi.submitTender,
    method: 'POST',
    data
  }).then(() => true)
}