// 验证用户名
export const nameRegular = (name: string) => name === '' || name.length > 20;

// 验证手机号
export const phoneRegular = (code: any) => {
  const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
  return !(reg.test(code));
}

// 验证图片类型
export const imageRegular = (type: string) => {
  const reg = new RegExp("(jpg|jpeg|gif|png)+", "gi");
  return !(reg.test(type));
}